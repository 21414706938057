import { setQueryParams as sourceSetQueryParams } from 'SourceUtil/Url/Url';
import getStore from 'Util/Store';

export * from 'SourceUtil/Url/Url';

/** @namespace EroswholesaleScandipwa/Util/Url/getBaseUrl */
export const getBaseUrl = () => {
    const { base_url = '' } = getStore().getState()?.ConfigReducer;

    return base_url;
};

/** @namespace EroswholesaleScandipwa/Util/Url/getAbsolutePath */
export const getAbsolutePath = (path) => {
    // eslint-disable-next-line fp/no-let
    let baseUrl = getBaseUrl();
    if (baseUrl) {
        baseUrl = baseUrl.replace(/\/+$/, '');
    }

    path = path.replace(/^\/+/, '');
    if (path.startsWith(baseUrl)) {
        path = path.slice(baseUrl.length);
        path = path.replace(/^\/+/, '');
    }

    return `${baseUrl}/${path}`;
};

/** @namespace EroswholesaleScandipwa/Util/Url/removeQueryParams */
export const removeQueryParams = (url) => {
    const urlObj = new URL(url);
    urlObj.search = '';

    return urlObj.toString();
};

/** @namespace EroswholesaleScandipwa/Util/Url/addQueryParam */
export const addQueryParam = (url, key, value) => {
    url = new URL(url);
    const params = new URLSearchParams(url.search);
    params.append(key, value);
    url.search = params.toString();

    return url.toString();
};

/** @namespace EroswholesaleScandipwa/Util/Url/setQueryParams */
export const setQueryParams = async (keyValueObject, location, history, callback) => {
    await sourceSetQueryParams(keyValueObject, location, history);

    if (typeof callback === 'function') {
        callback();
    }
};
